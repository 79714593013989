import {CodeBlock, CopyBlock, codepen, tomorrowNightBright, dracula, github} from "react-code-blocks";
import {
    Text,
    Heading,
    Input,
    FormControl,
    FormLabel,
    Center,
    Box,
    Button,
    Flex,
    VStack,
    Grid,
    GridItem
} from "@chakra-ui/react"
import Card from "../../../../../components/Card/Card";
import {BsArrow90DegDown} from "react-icons/bs";
import React, {useEffect, useState} from "react";
import CardHeader from "../../../../../components/Card/CardHeader";
import CardBody from "../../../../../components/Card/CardBody";
import {CodeTheme} from "./consts";
import StatusLabel from "./StatusLabel";
import DeleteDeploymentButton from "../../DeleteDeploymentButton";

const SECTIONS = [
    {
        title: 'Create a user and schema for epsio',
        code: "CREATE USER epsio_user WITH PASSWORD '<password_here>';"
    },
    {
        title: 'Give `epsio_user` necessary read permissions',
        code: "CREATE SCHEMA IF NOT EXISTS epsio;\n" +
            "GRANT CREATE, USAGE ON SCHEMA epsio TO epsio_user;\n" +
            "GRANT ALL PRIVILEGES ON ALL TABLES IN SCHEMA epsio TO epsio_user;\n" +
            "-- Note: You may need to specify additional schemas if needed\n" +
            "GRANT SELECT ON ALL TABLES IN SCHEMA public TO epsio_user;\n" +
            "ALTER DEFAULT PRIVILEGES IN SCHEMA public GRANT SELECT ON TABLES TO epsio_user;\n" +
            "GRANT CREATE, USAGE ON SCHEMA public TO epsio_user;"
    }
]

const empty = (str) => str === null || str === undefined || str.trim() === "";

const InstallScript = ({deployment, updateDeployment, reloadAccount}) => {
    const [password, setPassword] = useState("")
    const [databaseIP, setDatabaseIP] = useState("");
    const [databasePort, setDatabasePort] = useState("");
    const [databaseName, setDatabaseName] = useState("");

    const [databaseIpInvalid, setDatabaseIpInvalid] = useState(false);
    const [databasePortInvalid, setDatabasePortInvalid] = useState(false);
    const [databaseNameInvalid, setDatabaseNameInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [validationError, setValidationError] = useState(null);

    const connect = () => {
        let error = false;
        if (empty(databaseIP)) {
            setDatabaseIpInvalid(true);
            error = true;
        } else {
            setDatabaseIpInvalid(false);
        }
        if (empty(databasePort)) {
            setDatabasePortInvalid(true);
            error = true;
        } else {
            setDatabasePortInvalid(false);
        }
        if (empty(databaseName)) {
            setDatabaseNameInvalid(true);
            error = true;
        } else {
            setDatabaseNameInvalid(false);
        }
        if (empty(password)) {
            setPasswordInvalid(true);
            error = true;
        } else {
            setPasswordInvalid(false);
        }

        if (error) {
            setValidationError("Please fill in all fields");
            return;
        } else {
            setValidationError(null);
        }

        updateDeployment({
            ...deployment,
            connected: false,
            connectionError: null,
            connectionSetup: true,
            databaseInitialized: false,
            databaseInitializationSetup: true,
            databaseInitializationError: null,
            databaseIp: databaseIP,
            databasePort: databasePort,
            databaseName: databaseName,
            databasePassword: password
        });
    }

    useEffect(() => {
        if (deployment) {
            setDatabaseIP(deployment.databaseIp);
            setDatabasePort(deployment.databasePort);
            setDatabaseName(deployment.databaseName);
        }
    }, [deployment]);


    return (
        <Center>
            <Grid
                marginTop={"20px"}
                autoColumns="1fr"
                h="100%"
                w={"100%"}
                templateColumns='repeat(auto-fit, 1fr)'
                templateRows='1fr'
                gap={"30px"}
                templateAreas={`
                "RunOnDB ConnectionDetails"
              `}
            >
                <GridItem area={"RunOnDB"} height={"100%"}>
                    <Card height={"fit-content"} maxHeight={'calc(100vh - 300px)'}>
                        <CardHeader>
                            <Heading size='md'>Run on your database</Heading>
                        </CardHeader>
                        <div style={{padding: "5px 0", overflow: "auto", maxHeight: "100%", height: "100%"}}>
                            {
                                SECTIONS.map((section, idx) => {
                                    return (
                                        <Flex key={idx} direction={"column"}>
                                            <h2 style={{textAlign: "left", fontSize: "md", color: "black"}}>
                                                {section.title}
                                            </h2>
                                            <div style={{fontSize: "12pt", fontFamily: "inherit"}}>
                                                 <CopyBlock theme={CodeTheme}
                                                    language="sql" text={section.code} showLineNumbers={false} codeBlock={false} />
                                            </div>
                                        </Flex>
                                    )
                                })
                            }
                        </div>
                    </Card>
                </GridItem>
                <GridItem area={"ConnectionDetails"} height={"100%"}>
                    <Flex direction={"row"}>
                        <Box style={{paddingLeft: "20px", paddingBottom: "20px", paddingRight: "10px"}}>
                            <BsArrow90DegDown
                                style={{transform: "scaleX(-1)", height: "90px", width: "90px", marginLeft: "-40px"}}
                                color={"var(--chakra-colors-gray-300"}/>
                        </Box>
                        <Box>
                            <Text color={"gray.400"} style={{paddingBottom: "10px"}}><b style={{color: "black"}}>
                                Once you are finished running the commands on your database</b>, enter the password you
                                created
                                for <code>epsio_user</code>
                                as well as your database connection information</Text>
                        </Box>
                    </Flex>
                    <Flex direction={"column"} height={'calc(100vh - 460px)'}>
                        <Card height={"100%"}>
                            <CardHeader>
                                <Heading size='md'>Database Connection Details</Heading>
                            </CardHeader>
                            <CardBody style={{height: "100%"}}>
                                <VStack style={{padding: "5px 0", maxHeight: "100%", height: "100%", width: "100%"}} spacing={"20px"}>
                                    <Box overflow={"auto"} width={"100%"}>
                                        <FormControl>
                                            <FormLabel>Password you entered for <code>epsio_user</code></FormLabel>
                                            <Input type='password' isInvalid={passwordInvalid} value={password}
                                                   onChange={(e) => setPassword(e.target.value)}/>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Database Host and Port</FormLabel>
                                            <Flex>
                                                <Input isInvalid={databaseIpInvalid} type='text' value={databaseIP}
                                                       placeholder={"e.g. 10.0.1.1"}
                                                       onChange={(e) => setDatabaseIP(e.target.value)}/>
                                                <Center style={{padding: "0 3px"}}><b>:</b></Center>
                                                <Input isInvalid={databasePortInvalid} value={databasePort}
                                                       onChange={(e) => setDatabasePort(e.target.value)} type='text'
                                                       placeholder={"e.g. 5432"}/>
                                            </Flex>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Database Name</FormLabel>
                                            <Input isInvalid={databaseNameInvalid} type='text' value={databaseName}
                                                   onChange={(e) => setDatabaseName(e.target.value)}
                                                   placeholder={"e.g. my_db"}/>
                                        </FormControl>
                                    </Box>
                                    <Flex width={"100%"}>
                                        <Box>
                                            <Button onClick={connect} isLoading={deployment.connectionSetup}
                                                    colorScheme={"blue"}
                                                    style={{padding: "20px", marginRight: "5px"}}
                                                    fontSize={"md"}>Connect</Button>
                                        </Box>
                                        {
                                            validationError &&
                                            <Text color={"red.500"}
                                                  style={{marginBottom: "5px"}}>{validationError}</Text>
                                        }
                                        {
                                            deployment.databaseIp && <div>
                                                <StatusLabel waitingText={"Trying to connect..."}
                                                             finishedText={"Connected!"}
                                                             waiting={!deployment.connected}
                                                             error={deployment.connectionError}/>
                                                {
                                                    deployment.connected &&
                                                    <StatusLabel waitingText={"Waiting for Epsio to create functions..."}
                                                                 finishedText={"Finished"}
                                                                 waiting={!deployment.databaseInitialized}
                                                                 error={deployment.databaseInitializationError}/>
                                                }
                                            </div>
                                        }
                                    </Flex>
                                </VStack>
                            </CardBody>
                        </Card>
                        <Flex align={"flex-end"} justify={"flex-end"} style={{height: "100%"}}></Flex>
                        <div style={{position: "fixed", right: "55px", zIndex: 999, bottom: 10}}>
                            <DeleteDeploymentButton variant={"simple"} deployment={deployment}
                                                    reloadAccount={reloadAccount}/>
                        </div>
                    </Flex>
                </GridItem>
            </Grid>

        </Center>
    )
}

export default InstallScript;