export type Account = {
  id: string;
  email: string;
  deployments: Deployment[];
  isEmailConfirmed: boolean;
  stripeId: string | null;
  cardSetup: boolean;
  trialExpirationDate: string | null;
  nextBillingDate: string | null;
  token: string;
  displayDashboard: boolean | null;
}

export const USER_FIELDS = [
    "instanceType",
    "databasePassword",
    "databaseIp",
    "databasePort",
]

export type Deployment = {
  id: string;
  instanceType: string;
  instanceId: string;
  launchedCloudformation: boolean;
  launchError: string | null;
  timeInstanceLaunched: number | null;
  // awsExternalId: string;
  vpcId: string | null;
  subnetId: string | null;
  epsioSaidHello: boolean;
  deploymentType: string;
  databaseManagement: string;
  databaseType: string;
  databasePassword: string | null;
  databaseIp: string | null;
  databasePort: number | null;
  connectionSetup: boolean;
  connectionError: string | null;
  connected: boolean;
  databaseInitialized: boolean;
  databaseInitializationSetup: boolean;
  databaseInitializationError: string | null;
  replicationSetup: boolean;
  replicationInitialized: boolean;
  replicationError: string | null;
  singleInstanceSinkSource: boolean;
  versionNumber: string | null;
  versionModifier: string | null;
  cpuCount: number | null;
}
