import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex, FormControl,
  FormLabel,
  Heading,
  Spacer,
  theme,
  useClipboard,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {Elements} from '@stripe/react-stripe-js';

import CardSetupForm from './CardSetupForm';
import {loadStripe} from '@stripe/stripe-js';
import {Input, InputGroup, InputRightElement} from '@chakra-ui/react'
import {Text} from '@chakra-ui/react'
import {Account} from "../../types";
import moment from "moment";
import {CopyIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

const Settings = ({account, reloadAccount, updateAccount}: { account: Account, reloadAccount: any, updateAccount: any }) => {
  const [removeLoading, setRemoveLoading] = useState(false);
  const gap = theme.space[5];

  const userToken = account.token || '';

  const removeCard = () => {
    setRemoveLoading(true);
    updateAccount({...account, cardSetup: false}).then(() => {
      setRemoveLoading(false);
    });
  }

  const [showToken, setShowToken] = useState(false);
  const { hasCopied, onCopy } = useClipboard(userToken);

  const handleShowClick = () => setShowToken(!showToken);

  const displayValue = (value: string) => {
    return showToken ? value : `${value.slice(0, -4).replace(/./g, '*')}${value.slice(-4)}`;
  };

  return <div style={{height: "100%"}}>
    <Center style={{minHeight: "80%", flexDirection: "column", gap}} pb={gap}>
      <Card style={{minWidth: "60%", minHeight: "20%"}} hidden={userToken === ''}>
        <CardHeader pb={0}>
          <Heading size='md'>Access Token</Heading>
        </CardHeader>
        <CardBody>
          <Text as='b'>Copy your access token to create Docker/K8s deployments without the web console</Text>
          <br/>
          <br/>

          <InputGroup size='md'>
            <Input
                type='text'
                value={displayValue(userToken)}
                isReadOnly
            />
            <InputRightElement width='8rem'>
              <Button size='sm' onClick={handleShowClick} style={{backgroundColor:"transparent"}}>
                {showToken ? <ViewIcon /> :  <ViewOffIcon />}
              </Button>
              <Button size='sm' ml={2} onClick={onCopy} style={{backgroundColor:"transparent"}}>
                {hasCopied ? 'Copied' : <CopyIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </CardBody>
      </Card>
      <Card style={{minWidth: "60%", minHeight: "30%"}}>
        <CardHeader pb={0}>
          <Heading size='md'>Billing</Heading>
        </CardHeader>
        <CardBody>
          {
            account.cardSetup ? <><Alert status='info'>
              Your next billing date is&nbsp; <b>{moment(account.nextBillingDate).format('MMM DD, YYYY')}</b>
            </Alert>
              <br/>
            <Button colorScheme='red' onClick={removeCard}>Remove card</Button>
            </> : <>
              <Text as='b'>Add a credit card to continue using Epsio after your free trial</Text>
              <br/>
              <br/>
              <Elements stripe={stripePromise}>
                {/* @ts-ignore*/}
                <CardSetupForm account={account} updateAccount={updateAccount} reloadAccount={reloadAccount} />
              </Elements>
            </>
          }
        </CardBody>
      </Card>
    </Center>
  </div>
}

export default Settings;
