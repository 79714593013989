import React from 'react';
import ConfettiExplosion from "react-confetti-explosion";
import {Center, Heading, Text, Button} from "@chakra-ui/react";
import {Code, CopyBlock} from "react-code-blocks";
import {CodeTheme} from "./consts";
import CardHeader from "../../../../../components/Card/CardHeader";
import {useNavigate} from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  return (
      <div style={{width: "100%", height: "100%"}}>
        <Center>
          <ConfettiExplosion zIndex={999} />
        </Center>


        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>

          <div style={{marginTop: "40px"}}>
            <Center>
              <Text fontSize={"5xl"} fontWeight={"bold"} color={"blue.700"}>Successfully Deployed</Text>
            </Center>
            <Center>
              <Text fontSize={"md"} color={"gray.500"} textAlign={"center"} style={{width: "95%", maxWidth: "900px"}}>Congratulations on your new Epsio deployment!</Text>
            </Center>
            <Center style={{marginTop: "70px"}}>
              <div style={{maxWidth: "1000px"}}>
                <CardHeader>
                  <Heading size='lg'>Let's create our first view!</Heading>
                </CardHeader>
                <div style={{marginTop: "20px", marginBottom: "50px"}}>
                  <Text color={"gray.500"} fontSize={"lg"}>To create your first view, connect to your database, and run the following command with a custom name and query:</Text>

                  <div style={{fontSize: "14pt"}}>
                    <Code theme={CodeTheme} language={"sql"}
                          text={"\nCALL epsio.create_view('my_db.my_first_view', 'SELECT count(*) FROM my_db.my_table', true)\n\n"}/>
                  </div>
                  <Text color={"gray.500"} fontSize={"lg"}>Once finished, to see the results of your view, select from the view you created: </Text>
                  <div style={{fontSize: "14pt"}}>
                    <Code theme={CodeTheme} language={"sql"}
                          text={"\nSELECT * FROM my_db.my_first_view\n\n"}/>
                  </div>
                  <Text color={"gray.500"} fontSize={"lg"}>And that's it! To see more, go to our docs- <a href="https://docs.epsio.io" target={"_blank"}
                                                                                                          style={{color: "var(--chakra-colors-blue-500"}}>https://docs.epsio.io</a></Text>
                </div>

              </div>
            </Center>
          </div>

          <Center>
            <Button colorScheme={"blue"}  onClick={() => navigate('/admin/dashboard')}
                    style={{padding: "30px 50px", marginRight: "5px"}} fontSize={"2xl"}>Return to Dashboard
            </Button>
          </Center>
        </div>

      </div>
  )
}

export default Success;