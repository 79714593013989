// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useColorModeValue,
  Button,
  Image
} from "@chakra-ui/react";
// Custom components
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import {Link, useNavigate} from 'react-router-dom';

import React from "react";

import { useState } from 'react';
import {delete_, post, put} from "../../../client/client";
import DeleteDeploymentButton from "./DeleteDeploymentButton";
import pg_logo from "../../../assets/img/db_logos/postgres.png";
import mysql_logo from "../../../assets/img/db_logos/mysql.png";
import { FaPencilAlt } from "react-icons/fa";
import { CgEnter } from "react-icons/cg";
import {IoIosBuild} from "react-icons/io";



const AdditionalInformation = ({ deployment, textColor}) => {
  if (!deployment) return null;

  return (
    <Flex direction="column">
      {deployment.vpcId && (
        <Text fontSize="md" color={textColor} fontWeight="bold">
          vpc: {deployment.vpcId}
        </Text>
      )}
      {deployment.subnetId && (
        <Text fontSize="md" color={textColor} fontWeight="bold">
          subnet id: {deployment.subnetId}
        </Text>
      )}
      {deployment.host && (
        <Text fontSize="md" color={textColor} fontWeight="bold">
          host: {deployment.host}
        </Text>
      )}
    </Flex>
  );
};


const deploymentNameOrDefault = (deployment) => {
  return deployment.deploymentName || "Untitled " + deployment.id.slice(0, 6);
}

function EditableDeploymentName({ deployment }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(deploymentNameOrDefault(deployment));

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  const handleInputBlur = () => {
    if (name === "") {
      setName("Untitled " + deployment.id);
    } else if (name !== deployment.deploymentName) {
      deployment.deploymentName = name;
      put("/deployments/" + deployment.id, deployment).then((res) => {
      });
    }
    setIsEditing(false);
  };

   const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
    if (e.key === 'Escape') {
      setName(deploymentNameOrDefault(deployment));
      setIsEditing(false);
    }
  };

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <>
      {isEditing ? (
        <Input
          autoFocus
          value={name}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          size="md"
          fontWeight="bold"
        />
      ) : (
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          onClick={handleEditClick}
          display="inline-flex"
        >
          {name}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaPencilAlt fontSize="0.7em" style={{ marginLeft: '4px', color: 'gray', cursor: 'pointer' }} />
          </div>
        </Text>
      )}
    </>
  );
}


const Deployments = ({title, data, account, reloadAccount}) => {
  console.log(reloadAccount);
  let headers = ["Name", "Database", "Additional Information", "Size", "Status", ""];

  const textColor = useColorModeValue("gray.700", "white");
  const navigate = useNavigate();

  return (
      <Card overflowX={{sm: "scroll", xl: "hidden"}} variant={"panel"}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            {title}
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant='simple' color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                {headers.map((caption, idx) => {
                  return (
                      <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                        {caption}
                      </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((deployment) => <Tr key={deployment.id}>
                {
                    <>
                    <Td>
                      <Flex direction="column">
                        <EditableDeploymentName deployment={deployment} />
                      </Flex>
                    </Td>
                    <Td>
                        <Image
                            height="2rem"
                            maxWidth="100%"
                            src={deployment.databaseType === "postgres" ? pg_logo : mysql_logo}
                        />
                    </Td>
                    <Td>
                      <AdditionalInformation deployment={deployment} textColor={textColor}/>
                    </Td>
                      <Td>
                        <Flex direction="column">
                          <Text fontSize="md" color={textColor} fontWeight="bold">
                            {deployment?.instanceType ? (deployment.instanceType.charAt(0).toUpperCase() + deployment.instanceType.slice(1)) : deployment.cpuCount ? deployment.cpuCount + " vCPU" : "-"}
                          </Text>
                        </Flex>
                      </Td>
                      <Td>
                        {deployment.replicationInitialized ? deployment.cpuCount ? (
                          <Text fontWeight="bold" color="green.400">
                            Running
                          </Text>
                        ) : (
                          <Text fontWeight="bold" color="red.400">
                            Stopped
                          </Text>
                        ) : (
                          <Text fontWeight="bold">
                            Pending Setup
                          </Text>
                        )}
                      </Td>
                      <Td textAlign="right">
                        {!deployment.replicationInitialized ? (
                            <Button size="sm" ml="5px" onClick={() => navigate(`/admin/deployment/${deployment.databaseType}/${deployment.id}`)} title="Continue Setup" variant="ghost">
                              <IoIosBuild />
                            </Button>
                        ) : account?.displayDashboard ? (
                            <Button size="sm" ml="5px" onClick={() => navigate(`/admin/deployment/${deployment.databaseType}/${deployment.id}/dashboard`)} title="View Dashboard" variant="ghost">
                              <CgEnter />
                            </Button>
                        ) : ""
                        }
                        <DeleteDeploymentButton deployment={deployment} reloadAccount={reloadAccount} variant="icon" />
                      </Td>
                    </>
                }
              </Tr>)}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
  );
};

export default Deployments;
